import React from "react"
import "./Card.scss"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { imageUrl } from "../../helpers/ImageUrl"
import Icon from "../Icon/Icon"

function Card(props) {
  const { title, image, route, type, color, onClickCard, instagramImage } =
    props

  function clickCard(type) {
    if (["vote", "top-list", "podcast", "action","nostalgie-plus-home", "nostalgie-plus-sub"].includes(type)) {
      navigate(route)
    } else {
      onClickCard()
    }
  }

  return (
    <div className={`card ${type}`}>
      {type !== "instagram" ? (
        <div onClick={() => clickCard(type)} className="card__inner">
          <div className="card__visual">
            <img src={imageUrl(image, 385, 250)} alt={title} />
          </div>
          <div className="card__content" style={{ backgroundColor: color }}>
            <h3 className="card--title">{title}</h3>
          </div>
        </div>
      ) : (
        <a
          href="https://www.instagram.com/nostalgiewhatafeeling/"
          target="_blank"
          rel="noopener noreferrer"
          className="card__inner"
        >
          <div className="card__visual">
            <img src={instagramImage} alt={title} />
          </div>
          <div className="card__content" style={{ backgroundColor: color }}>
            <div className="instagram">
              <span className="instagram--icon">
               <Icon name="instagram" />
              </span>
              <p className="instagram--name">nostalgiewhatafeeling</p>
            </div>
          </div>
        </a>
      )}
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  route: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
}

export default Card
