import {fromDateFormat, transformResponse} from "../services/preprApi";

export function formatMe(me) {
  return transformResponse({
    ...me,
    email: me.emails ? me.emails.items[0].email : "",
    phone: me.phones
      ? me.phones.items.length > 0
        ? me.phones.items[0].phone
        : ""
      : "",
    dateOfBirth: me.date_of_birth
      ? fromDateFormat(me.date_of_birth).toFormat( "dd/LL/yyyy")
      : "",
  })
}
