import React from "react"

function GridEmpty(props) {
  const { children } = props

  return (
    <div className="grid--more">
      <p>{children}</p>
    </div>
  )
}

export default GridEmpty
