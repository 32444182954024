import React from "react"
import { Link } from "gatsby"
import hero from "../../resources/images/hero-contact.jpg"
import Title from "../Text/Title"
import BodyContent from "../Text/BodyContent"
import MainContent from "../Layout/MainContent"

export default function Error404() {
  return (
    <MainContent imageInternal={hero}>
      <Title>Oeps, we kunnen deze pagina niet terug vinden.</Title>
      <BodyContent>
        <p>
          Ga naar de <Link to={"/"}>homepage</Link>.
        </p>
      </BodyContent>
    </MainContent>
  )
}
