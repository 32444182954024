import React from "react"
import Iframe from "react-iframe"

function Qualifio(props) {
  const { src } = props

  return (
    <Iframe
      url={src}
      width="100%"
      height="900"
      frameBorder={0}
      id="myId"
      className="myClassname"
      display="initial"
      position="relative"
    />
  )
}

export default Qualifio
