import React, {useEffect, useMemo, useState} from "react"
import "./HomeModal.scss"
import PostFooter from "../News/PostFooter"
import Title from "../Text/Title"
import {
  getSingleImage,
  getTitle,
  getPublishOn,
  getQualifio,
  getQualifioCampaignId,
  getSingleImageName,
  getQualifioParticipateLimit,
} from "../../helpers/Publications"
import Qualifio from "../Qualifio/Qualifio"
import { ACTION_MODEL_ID, NEWS_MODEL_ID } from "../../constants/models"
import { returnLayoutSection } from "../../helpers/Layout"
import { imageUrl } from "../../helpers/ImageUrl"
import InnerHTML from "dangerously-set-html-content"
import { getImageType } from "../../helpers/Prepr"
import { Helmet } from "react-helmet"
import BtnRounded from "../Buttons/BtnRounded";
import global from "../../global/global";
import {useGuestApi} from "../../hooks/useApi";
import {useOAuthTokens} from "../../hooks/useOAuthTokens";
import {formatMe} from "../../helpers/User";
import {useAccount} from "../../hooks/useAccount";
import Icon from "../Icon/Icon"

function HomeModal({ visible, onClickClose, data, animating }) {
  const { getQualifioToken, getMeByAccessToken } = useGuestApi();
  const [liveStream, setLiveStream] = useState(false)
  const [qualifioToken, setQualifioToken] = useState(null);
  const { accessToken } = useOAuthTokens()
  const {me} = useAccount()
  const [authenticatedUser, setAuthenticatedUser] = useState(null)

  setTimeout(() => {
    if (window.instgrm && visible) {
      window.instgrm.Embeds.process()
    }
    if (window.opix && visible) {
      opix("event", "View", { publication: data.id })
    }
  }, 1000)

  useEffect(() => {
    if(accessToken && Object.keys(me).length === 0) {
      getMeByAccessToken(accessToken).then(response => {
        setAuthenticatedUser(formatMe(response));
      });
    } else if (me ? Object.keys(me).length !== 0 : false) {
      setAuthenticatedUser(me);
    }
  }, [accessToken, getMeByAccessToken, me])

  useEffect(() => {
    if(authenticatedUser && ACTION_MODEL_ID === data.model_id && getQualifioCampaignId(data.qualifio_campaign_id)) {
      const address = authenticatedUser?.addresses?.items?.length > 0 ? authenticatedUser.addresses.items[0] : null;
      const obj = {
        payload: {
          campaignId: getQualifioCampaignId(data.qualifio_campaign_id),
          userId: authenticatedUser.id,
          limit: {
            nb: getQualifioParticipateLimit(data.qualifio_participate_limit),
          },
          form: {
            firstname: authenticatedUser?.firstName,
            lastname: authenticatedUser?.lastName,
            email: authenticatedUser?.email,
            zipcode: address?.zipcode,
            country: address?.country,
            address: address?.street,
            number: address?.house_number,
            city: address?.city,
            locality: address?.city,
            birthday: authenticatedUser?.dateOfBirth,
            gender: authenticatedUser?.gender,
            phone: authenticatedUser?.phone?.slice(2),
          }
        }
      }
      getQualifioToken(obj).then((response) => {
        if(response.status === 200) {
          const { token } = response.data;
          setQualifioToken(token);
        }
      });
    }
  }, [authenticatedUser, data, getQualifioToken]);

  useEffect(() => {
    if (data?.livestream_url?.body && visible) {
      setLiveStream(true)
    }
  }, [data, visible])

  return (
    <>
      {visible && (
        <div className={`home__modal${animating ? " animating" : ""}`}>
          <div className="modal__inner">
            <button className="modal__close" onClick={() => {
              setLiveStream(false)
              onClickClose()
            }}>
              <Icon name="close" />
            </button>
            <div className="modal__box">
              <div className="modal__visual">
                <img
                  src={imageUrl(
                    getSingleImage(
                      getImageType(
                        "image_website_detail",
                        data.image,
                        null,
                        null,
                        data.image_website_detail
                      )
                    ),
                    900,
                    420
                  )}
                  alt={getSingleImageName(data.image)}
                />
              </div>
              <div className="modal__content">
                <Title>{getTitle(data.title)}</Title>
                {data.layout
                  ? Array.isArray(data.layout.items)
                    ? data.layout.items.map((item, i) => {
                        return returnLayoutSection(item, i)
                      })
                    : null
                  : null}

                {data.model_id !== ACTION_MODEL_ID && data.iframe && (
                  <InnerHTML html={data.iframe.body} />
                )}
                {[ACTION_MODEL_ID, NEWS_MODEL_ID].includes(data.model_id) &&
                  data.qualifio && (
                    <>
                      <>
                        {authenticatedUser ?
                          <Qualifio src={`${getQualifio(data.qualifio)}&qual_token=${qualifioToken}`}/> :
                          <div className="not-logged-in">
                            <p>
                              <strong>Aanmelden om deel te nemen </strong>
                              <br/>
                              Je moet aangemeld zijn om deel te nemen aan deze actie.
                              < br/>
                            </p>
                            <BtnRounded primary large
                                        onClick={() => global.modal.setShowAuthModal(true)}>
                              Aanmelden
                            </BtnRounded>
                          </div>
                        }
                      </>
                    </>
                  )}
                {liveStream && (
                  <>
                    <Helmet>
                      <script
                        src="/livestream/player.js"
                        async
                        onload="playerLoaded();"
                      />
                      <script>
                        {`
                        function playerLoaded() {
                          var player = new Playerjs({id:"fluidPlayer", file: "${data.livestream_url.body}", poster:"/livestream/poster.jpg"});
                        }
                      `}
                      </script>
                    </Helmet>
                    <div className="livestream-player" id="fluidPlayer"></div>
                  </>
                )}
              </div>
              <PostFooter date={getPublishOn(data.publish_on)} />
            </div>
          </div>
          <div onClick={() => {
            setLiveStream(false)
            onClickClose()
          }} className="modal__backdrop" />
        </div>
      )}
    </>
  )
}

export default HomeModal
