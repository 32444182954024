import React, { useState, useEffect } from "react"
import MainContent, { MainContentLoading } from "../Layout/MainContent"
import Title from "../Text/Title"
import PostFooter from "./PostFooter"
import { useGuestApi } from "../../hooks/useApi"
import withGuestLayout from "../../../src/hoc/withGuestLayout"
import { getTitle, getPublishOn, getQualifio } from "../../helpers/Publications"
import { formatPageInformation } from "../../helpers/Meta"
import { returnLayoutSection } from "../../helpers/Layout"
import InnerHTML from "dangerously-set-html-content"
import "./NewsDetail.scss"
import Error404 from "../Errors/404"
import Qualifio from "../Qualifio/Qualifio"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"


function Detail({ slug }) {
  const { getPublicationBySlug, setPageInformation } = useGuestApi()
  const [newsItem, setNewsItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [liveStream, setLiveStream] = useState(false);

  useEffect(
    function fetchPublications() {
      getPublicationBySlug(slug).then(response => {
        setLoading(false)
        setPageInformation(formatPageInformation(response, "article"))
        setNewsItem(response)
        if (window.instgrm && response) {
          window.instgrm.Embeds.process()
        }
        if (window.opix && response) {
          opix("event", "View", { publication: response.id })
        }

        if (response === null) {
          navigate("/nieuws")
        }
      })
    },
    [slug]
  )

  useEffect(() => {
    if (newsItem?.livestream_url?.body) {
      setLiveStream(true)
    }
  }, [newsItem])

  return (
    <>

      {loading && <MainContentLoading />}
      {newsItem && (
        <>
          <MainContent {...newsItem} imageType={"image_website_detail"}>
            <div className="news__detail">
              <Title>{getTitle(newsItem.title)}</Title>
              {newsItem.layout
                ? Array.isArray(newsItem.layout.items)
                  ? newsItem.layout.items.map((item, i) => {
                      return returnLayoutSection(item, i)
                    })
                  : null
                : null}
              {newsItem.iframe && (
                <>
                  <InnerHTML html={newsItem.iframe.body} />
                </>
              )}
              {newsItem.qualifio && (
                <Qualifio src={getQualifio(newsItem.qualifio)} />
              )}
              {liveStream && (
                <>
                <Helmet>
                  <script src="/livestream/player.js" async onload="playerLoaded();" />
                  <script>
                    {`
                      function playerLoaded() {
                        var player = new Playerjs({id:"fluidPlayer", file: "${newsItem.livestream_url.body}", poster:"/livestream/poster.jpg"});
                      }
                    `}
                  </script>
                  </Helmet>
                  <div className="livestream-player" id="fluidPlayer"></div>
                </>
              )}
              <PostFooter date={getPublishOn(newsItem.publish_on)} />
            </div>
          </MainContent>
        </>
      )}
      {!newsItem && !loading && <Error404 />}
    </>
  )
}

export default withGuestLayout(Detail, {
  page: "Nieuws",
})
