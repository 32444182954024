import React from "react"
import "./NewsOverview.scss"
import { useLocation } from "@reach/router"
import NewsGrid from "../../components/Grid/NewsGrid"
import Container from "../../components/Layout/Container"

function NewsOverview() {
  const history = useLocation()

  return (
    <Container page="Nieuws">
      <div className="news container">
        <div className="news__grid">
          <NewsGrid history={history} />
        </div>
      </div>
    </Container>
  )
}

export default NewsOverview
