import React from "react"
import { Router } from "@reach/router"
import NewsOverview from "../components/News/Overview"
import Detail from "../components/News/Detail"

const News = () => {
  return (
    <Router>
      <NewsOverview path="nieuws" />
      <Detail path="nieuws/:slug" />
    </Router>
  )
}

export default News
