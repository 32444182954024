import React from "react"
import Skeleton from "react-loading-skeleton"

export default function LoadingCard() {
  return (
    <div className={`card loading-card`}>
      <div onClick={() => null} className="card__inner">
        <div className="card__visual">
          <Skeleton width={"100%"} height={"100%"} circle={false} />
        </div>
        <div className="card__content">
          <Skeleton width={250} height={29} />
        </div>
      </div>
    </div>
  )
}
